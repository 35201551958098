import axios from '@axios'
import OffersEnums from "@/enums/OffersEnums";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(OffersEnums.MODULE_BE_BASE_URL, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        add(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(OffersEnums.MODULE_BE_BASE_URL, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        update(ctx, {id, data}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(OffersEnums.MODULE_BE_BASE_URL + '/' + id, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        delete(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(OffersEnums.MODULE_BE_BASE_URL + '/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(OffersEnums.MODULE_BE_BASE_URL + '/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    }
}
